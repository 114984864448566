<template>
    <div class="MerchantSettled">
        <div class="MerchantSettled-header"></div>
        <div class="MerchantSettled-center" style="height: 1000px;">
            <div class="banner_wrapper"
                 style="position:absolute;transform: translateX(-50%);top:100px;left: 8%;z-index: 100; display: flex">
                <div class="product" style="width: 431px;position: relative;display: inline-block">
                </div>
                <div class="desc">
                    <h1>云餐巴商家端</h1>
                    <h2>暖心·暖城·暖万家</h2>
                    <div class="app-wrapper">
                        <div class="app">
                            <img :src="VUE_APP_BASE_API+'/officialWebsite/6fc4a22ac2d3adc76dc860183f0a3e9%402x.png'"
                                 alt="商家端logo" width="118">
                            <p>商家端</p>
                        </div>
                        <div class="download">
                            <a href="https://ptcb.oss-cn-hangzhou.aliyuncs.com/apk/app-mealbar-mch-release.1.7.apk">
                                <img :src="VUE_APP_BASE_API+'/officialWebsite/%E7%BB%84%202%20%E6%8B%B7%E8%B4%9D%402x.png'"
                                     alt="">
                            </a>
                            <a href="https://apps.apple.com/cn/app/%E4%BA%91%E9%A4%90%E5%B7%B4%E5%95%86%E5%AE%B6%E7%89%88/id1552373411">
                                <img :src="VUE_APP_BASE_API+'/officialWebsite/%E7%BB%84%202%402x.png'" alt="">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="MerchantSettled-bottom">
            <div class="content">
                <ul>
                    <li v-for="(item,index) in pageInfo" :key="index">
                        <p class="title" style="color:#D90D14">{{item.title||"暂无标题"}}</p>
                        <p class="content" style="color:#DB0F18" v-html="item.desc"></p>
<!--                        <img :src="VUE_APP_BASE_API+item.img" alt="图片" height="600" >-->
                        <img :src="VUE_APP_BASE_API+item.img" alt="图片">
                    </li>
                </ul>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: "MerchantSettled",

        data() {
            return {
                VUE_APP_BASE_API: process.env.VUE_APP_BASE_API,
                pageInfo: [
                    {
                        img: '/min/%E7%BB%84%2014%402x.png',
                        title: '订单管理',
                        desc: '及时处理，高效快捷'
                    },
                    {
                        img: '/min/%E7%BB%84%2014%402x_1.png',
                        title: '经营分析',
                        desc: '营业统计，一览无余'
                    },
                    {
                        img: '/min/%E7%BB%84%2014%402x_2.png',
                        title: '商品管理',
                        desc: '信息更迭，轻松方便'
                    },
                    {
                        img: '/min/%E7%BB%84%2014%402x_3.png',
                        title: '店铺管理 ',
                        desc: '功能聚会，一手掌握'
                    }
                ],
            }
        }

    }
</script>

<style scoped>
    .MerchantSettled {
        position: relative;
        /*background-image: url("https://ptcb.oss-cn-hangzhou.aliyuncs.com/min/img-3.jpg");*/
    }

    .MerchantSettled-center {
        position: relative;
        background: url("https://ptcb.oss-cn-hangzhou.aliyuncs.com/min/img-3.jpg") center center/100% auto no-repeat;
    }

    .desc {
        display: inline-block;
        margin: 200px 0 0 260px;
        vertical-align: top;
    }


    .MerchantSettled-bottom {
        position: relative;
        background: url("https://ptcb.oss-cn-hangzhou.aliyuncs.com/min/img-9.jpg") center center/100% auto no-repeat;
        padding-bottom: 107px;
    }


    /*---------------------------*/
    .banner .desc {
        display: inline-block;
        margin: 77px 0 0 11%;
        width: 806px;
        vertical-align: top;
    }

    .desc h1 {
        font-size: 67px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #C8161D;
        font-weight: 800;
    }

    .desc h2 {
        font-size: 30px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #3E3B3B;
        line-height: 30px;
        padding: 33px 0 87px;
    }

    .desc h2:after {
        content: "";
        display: block;
        margin-top: 33px;
        width: 53px;
        height: 4px;
        background: red;
        background: #E60012;
    }

    .desc .app-wrapper {

    }

    .desc .app {
        display: inline-block;
        margin-right: 40px;
    }

    .app p {
        width: 100%;
        text-align: center;
        line-height: 30px;
        padding-top: 17px;
        margin-right: 27px;
        font-size: 25px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #3E3B3B;
    }

    .app-wrapper .download {
        height: 39px;
        display: inline-block;
        vertical-align: top;
    }

    .app-wrapper .download img {
        height: 39px;
        display: block;
        cursor: pointer;
    }

    .app-wrapper .download img:first-child {
        margin: 10px 0 19px 0;
    }


     .content{
        /*margin-top: 110px;*/
         text-align: center;
    }
     .content li{
        display: inline-block;
        width: 20%;
        vertical-align: top;
         margin-top: 100px;
    }
     .content li .title{
         font-size: 34px;
         font-family: PingFang SC;
         font-weight: bold;
         color: #C7161E;
         text-align: center;
    }
     .content li .content{
         font-size: 24px;
         font-family: PingFang SC;
         font-weight: 400;
         color: #D90E15;
         text-align: center;
     }

    .content li img {
        width: 100%;
    }
</style>
