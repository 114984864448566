import Router from 'vue-router'
import Layout from '@/layouts/Index'
import Home from "@/pages/Home";
import MerchantSettled from "@/pages/MerchantSettled";
import PrivacyPolicy from "@/pages/PrivacyPolicy";
import Vue from 'vue'
// import roleManage from "@/pages/roleManage";

Vue.use(Router)

export default new Router({
    // mode: 'history',
    // base: __dirname,
    routes: [
        {
            path: '/',
            component: Layout,
            redirect: '/Home',
            children: [
                {
                    path: '/Home',
                    component: Home,
                    meta: {
                        logo: '/officialWebsite/%E5%9B%BE%E5%B1%82%203%402x.png',
                    }
                },
                {
                    path: '/MerchantSettled',
                    component: MerchantSettled,
                    meta: {
                        logo: '/officialWebsite/%E5%9B%BE%E5%B1%82%2031%402x.png',
                    }
                },
                {
                    path: '/PrivacyPolicy',
                    component: PrivacyPolicy,
                    meta: {
                        logo: '/officialWebsite/%E5%9B%BE%E5%B1%82%2031%402x.png',
                    }
                },
            ]
        }
    ]

})
