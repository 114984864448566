<template>
    <div class="container-wrapper">
        <div class="fix-header">
            <div class="logo" style="position: absolute;top:39px;left:13.8%;z-index: 100">
                <img :src="VUE_APP_BASE_API+$route.meta.logo||headerLogo" alt="logo" height="43px">
            </div>
            <div class="tabs">
                <span :class="{'active':tabIndex === index}" v-for="(item,index) in tabs" :key="index"
                      @click="toChangeTab({index:index,path:item.path})">{{item.name}}</span>
            </div>
        </div>
        <div style="box-sizing:border-box;min-height: 100%;padding-bottom: 381px">
            <router-view/>
        </div>
        <div class="footer" :style="{'background-image':'url('+ VUE_APP_BASE_API+'/officialWebsite/footer-bg.jpg)'}">
            <div class="footer-wrapper">
                <h3>联系我们</h3>
                <ul class="content">
                    <li v-for="(item,index) in connectUs" :key="index">
                        <p>{{item.title}}</p>
                        <p>{{item.content}}</p>
                    </li>
                </ul>
                <div class="logo">
                    <img :src="VUE_APP_BASE_API+footerLogo" alt="logo" height="53">
                    <div>
                        <a href="https://beian.miit.gov.cn/"  target="_blank" rel="" style="color: rgb(254, 254, 255); text-decoration: none; font-family: 微软雅黑; font-size: 12px; font-weight: normal;">闽ICP备2021013779号-1</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Index",
        data(){
            return {
                VUE_APP_BASE_API: process.env.VUE_APP_BASE_API,
                headerLogo: '/officialWebsite/%E5%9B%BE%E5%B1%82%203%402x.png',
                tabs: [
                    {name: '首页', path:'/Home'},
                    {name: '商家入驻', path:'/MerchantSettled'},
                    {name: '隐私和政策', path:'/PrivacyPolicy'},
                ],
                tabIndex: 0,
                connectUs: [
                    {
                        title: "公司电话",
                        content: "400-0594-517"
                    },
                    {
                        title: "公司地址",
                        content: "莆田市城厢区荔城中大道市政南广场2号楼2层"
                    },
                ],
                footerLogo: '/officialWebsite/%E5%9B%BE%E5%B1%82%2010%402x.png',
            }
        },
        created() {
            this.tabIndex = this.tabs.findIndex(e=>e.path === this.$route.path)
        },
        methods:{
            toChangeTab({index, path}) {
                this.tabIndex = index
                this.toPage({path: path})
            },
            toPage({path}) {
                this.$router.push({path: path})
            }
        }
    }
</script>

<style scoped>

    /*********** container-wrapper \/ **********/
    .container-wrapper{
        flex:1;
        position: relative;
        /*height: 100%;*/
    }
    /*********** container-wrapper /\ **********/



    /*********** fix-header \/ **********/
    .container-wrapper .fix-header{
        position: absolute;
        top:0;
        left:0;
        width: 100%;
    }
    .container-wrapper .tabs {
        height: 36px;
        line-height: 30px;
        position: absolute;
        top: 39px;
        right: 16.72%;
        z-index: 100
    }

    .tabs span {
        display: inline-block;
        height: 36px;
        font-size: 18px;
        font-weight: 400;
        color: #FFFFFF;
        margin-right: 72px;
        cursor: pointer;
    }

    .tabs span:last-child {
        margin-right: 0;
    }

    .tabs span.active {
        font-size: 24px;
        border-bottom: 1px solid #EC322F;
        color: #EC322F;
    }
    /*********** fix-header /\ **********/


    /*********** footer /\ **********/
    .footer{
        position: absolute;
        bottom: 0;
        left: 0;
        background: no-repeat center center/100% auto;
        height: 383px;
        width: 100%;
        font-size: 25px;
        line-height: 31px;
        box-sizing: border-box;
        padding-top: 78px;
        text-align: center;
        overflow: hidden;
    }
    .footer-wrapper {
        display: inline-block;
        width: 1413px;
        height: 100%;
        text-align: left;
    }

    .footer h3{
        font-size: 30px;
        color: #FFFFFF;
        margin-bottom: 32px;
    }

    .footer .content li{
        display: inline-block;
        margin-right: 96px;
    }
    .footer .logo {
        text-align: center;
        margin-top: 71px;
    }
</style>
