<template>
    <div>
        <div class="header"></div>
        <div class="privacy">
            <h4 class="privacy-header">引言</h4>
            <p>欢迎使用" 云餐巴"提供的产品和服务！ 云餐巴（或简称"我们"）深知个人信息对您的重要性，我们一向庄严承诺保护使用我们的产品和服务（以下统称" 云餐巴服务"）之用户（以下统称"用户"或"您"）的个人信息及隐私安全。您在使用 云餐巴服务时，我们可能会收集和使用您的相关个人信息。我们希望通过《 云餐巴隐私政策》（以下简称"本政策"）向您说明我们在收集和使用您相关个人信息时对应的处理规则，以及我们为您提供的访问、更正、删除和保护这些个人信息的方式，以便更好的保障您的权益。</p>
            <p><strong>本政策将帮助您了解以下内容：</strong></p>
            <p>一、我们如何收集和使用您的个人信息</p>
            <p>二、我们如何保存您的信息及个人信息跨境传输</p>
            <p>三、我们如何使用 Cookie&ensp;和同类技术</p>
            <p>四、我们如何共享、转让、公开披露您的个人信息</p>
            <p>五、我们如何保护您的个人信息安全</p>
            <p>六、您如何管理您的个人信息</p>
            <p>七、第三方服务</p>
            <p>八、未成年人信息的保护</p>
            <p>九、通知和修订</p>
            <p>十、如何联系我们</p>
            <p><strong>【特别提示】还请您在使用</strong><strong> 云餐巴</strong><strong>服务前，仔细阅读（未成年人请在监护人陪同下阅读）并了解本政策（特别是加粗或下划线标注的内容），以做出适当选择。一旦您使用或在我们更新本政策后继续使用我们的产品或服务，即意味着您同意本政策并同意我们按照本政策处理您的相关个人信息。</strong></p>
            <p><strong>本政策适用于</strong><strong> 云餐巴</strong><strong>服务。如我们提供的某一单项服务不适用本政策的，该服务中会以适当方式明示排除适用本政策。</strong></p>
            <h2><strong>一、我们如何收集和使用您的个人信息</strong></h2>
            <p><strong>我们会出于本政策所述的以下目的，收集和使用您的个人信息：</strong></p>
            <h3><strong>（一）帮助您完成注册</strong></h3>
            <p>为完成创建账号，<strong>您需要提供以下信息：本人的手机号码、电子邮箱地址，创建的用户名和密码。</strong>如果您仅需使用浏览、搜索等基本服务，您不需要创建账号及提供上述信息。</p>
            <p>在注册过程中，如果您提供以下<strong>额外信息</strong>，将有助于我们给您提供个性化的商品推荐和更优的购物体验：您的昵称、性别、出生年月日、头像、感兴趣的类别以及您的实名认证相关信息。</p>
            <h3><strong>（二）向您提供商品或服务</strong></h3>
            <p>1、您向我们提供的信息</p>
            <p><strong>预定或下单：</strong>当您准备预定或下单购买 云餐巴网站的商品或服务时，您至少需要向我们提供收货人姓名、收货地址、邮政编码及收货人联系电话；<strong>&nbsp;您在</strong><strong> 云餐巴</strong><strong>网站上购买跨境商品或预定票务服务、购买手机号卡或合约机等商品时，您还可能需要根据国家法律法规或服务提供方（包括票务销售方、旅行社及其授权的代理方、基础电信运营商）的要求提供您的实名信息，这些实名信息可能包括您的个人身份信息</strong>（如身份证、护照、驾驶证、社保卡、居住证）、个人生物识别信息（如指纹、声纹、掌纹、虹膜、面部识别特征）等个人敏感信息。</p>
            <p>您可以通过 云餐巴为其他人订购商品或服务，您需要提供该实际订购人的前述个人信息。<strong>向</strong><strong> 云餐巴</strong><strong>提供该实际订购人的前述个人信息之前，您需确保您已经取得其授权同意。</strong></p>
            <p><strong>支付结算：</strong>在您预定或下单后，您可以选择我们的关联方或与我们合作的第三方支付机构（包括网易宝支付、支付宝支付、微信支付、Apple Pay、京东支付及银联、网联等支付通道，以下称"支付机构"）所提供的支付服务。支付功能本身并不收集您的个人信息，但我们需要将您的 云餐巴订单号与交易金额信息同这些支付机构共享以实现其确认您的支付指令并完成支付。</p>
            <p><strong>交付产品或服务：</strong>在当您下单并选择货到付款或在线完成支付后，我们的关联方或与我们合作的第三方配送公司（包括顺丰、EMS、圆通、申通、中通、韵达、天天快递、京东快递等，以下称"配送公司"）将为您完成订单的交付。<strong>您知晓并同意我们的关联方或与我们合作的第三方配送公司会在上述环节内使用您的订单信息以保证您的订购的商品能够安全送达。如果您拒绝共享此类信息，我们将无法完成相关交付服务。</strong></p>
            <p>配送公司的配送员在为您提供配送服务的同时，基于某些业务法律上要求实名认证的需求，会协助您完成实名认证。如您购买我们的手机号卡时，我们或配送公司在此环节会使用您的身份证通过国家有权认证机构的专有设备对您的身份进行核验，我们并没有收集您的身份证信息，且我们或配送公司的配送员均须遵守公司保密制度的规定。</p>
            <p><strong>客服与售后服务：</strong>当您与我们的客服取得联系时，我们的系统可能会记录您与客服之间的通讯记录，以及使用您的账号信息和订单信息以便核验身份；当您需要客服协助您修改有关信息（如配送地址、联系方式等）时，您可能还需要提供上述信息外的其他信息以便完成修改；当您需要我们提供与您账号或订单相关的客户服务时，我们可能会查询您的相关订单信息以便给予您适当的帮助和处理。</p>
            <p><strong>为向您提供更便捷、更优质、个性化的产品及/或服务，努力提升您的体验，我们在向您提供的以下附加服务中可能会收集和使用您的个人信息。如果您不提供上述信息，不会影响您使用</strong><strong> 云餐巴</strong><strong>的浏览、搜索、购买等基本服务，但您可能无法获得这些附加服务给您带来的用户体验。这些附加服务包括：</strong></p>
            <ol>
                <li>位置信息：我们会基于您的地理位置为您个性化的商品推荐服务。当您开启设备定位功能并使用我们基于位置提供的相关服务时，我们可能会收集和使用您的位置信息，以实现我们为您提供您本地周边生活服务的目的（包括您无需手动输入自身地理位置即可获得相关服务，以及估算商家与您之间的实际距离方便您进行消费决策）。我们会使用有关技术对您的实际位置进行定位，我们仅收集您当时所处的地理位置，但不会将您各时段的位置信息进行关联以判断您的行踪轨迹。</li>
                <li>基于电话权限的附加功能：您可以使用该功能授权我们获取IMEI、IMSI等设备识别符信息，以用于保障您的账号和交易安全。</li>
                <li>基于相机/摄像头的附加服务：您可以使用该功能进行扫码用于登录、购物、领取优惠信息，直接拍摄并上传图片用于评价，以及特定场景下经您授权的扫码支付、参与活动等您明确知悉的场景。请您知晓，即使您已同意开启摄像头权限，我们也仅会在您主动点击客户端摄像头图标拍照时通过摄像头获取照片信息。</li>
                <li>基于相册（图片库/视频库）的图片/视频访问及上传的附加服务：您可以使用该功能上传您的照片/图片/视频，以实现更换头像、发表商品评价、拍照购物或与客服沟通提供证明等功能。</li>
                <li>基于麦克风的语音技术相关附加服务：您可在开启麦克风权限后使用麦克风帮助您与客服联系或与客服机器人实现语音交互，在这些功能中我们会收集您的录音内容以响应您的客服及争议处理等需求。请您知晓，即使您已同意开启麦克风权限，我们也仅会在您主动点击客户端内麦克风图标或录制视频时通过麦克风获取语音信息。</li>
                <li>基于通讯录信息的附加功能：我们将在您开启通讯录权限后收集您的通讯录信息使您在购物时可以更便利地取用您通讯录内的联系人信息，无需再手动输入。</li>
            </ol>
            <p>您理解并同意，上述附加服务可能需要您在您的设备中开启您的位置、电话状态、摄像头（相机）、相册（图片库）、麦克风（语音）、通讯录的访问权限，以实现这些权限所涉及信息的收集和使用。您可在您的设备设置或我们的相关客户端"设置-隐私-系统权限"中逐项查看上述权限的状态，并可自行决定这些权限随时的开启或关闭。请您注意，您开启任一权限即代表您授权我们可以收集和使用相关个人信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关个人信息，也无法为您提供该权限所对应的服务。但是，您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。</p>
            <p><strong>为便于您对您的订单进行管理，我们会收集您的订单信息向您展示；我们也会使用您的订单信息来进行身份核验、确定交易、支付结算、完成配送、订单查询、提供客服咨询与售后服务或用来判断您的交易是否存在异常以便保护您的交易安全。</strong></p>
            <p><strong>您在</strong><strong> 云餐巴</strong><strong>上生成的订单中，将可能包含您的身份信息、联络信息、行程信息、住宿信息、支付信息、个人健康生理信息等，这些都属于敏感信息，请您谨慎向他人展示或对外提供</strong>。我们也将会在展示订单信息时，在保证信息可用性的基础上尽量做去标识化处理（如对订单中收件人的手机号进行脱敏处理）。</p>
            <p><strong>2、我们在您使用服务过程中可能收集的信息</strong></p>
            <p>为向您提供更契合您需求的页面展示和搜索结果、了解产品适配性、识别账号异常状态，我们会收集关于您使用的服务以及使用方式的信息并将这些信息进行关联，这些信息包括：</p>
            <ul>
                <li><strong>日志信息：</strong>当您使用我们的网站或客户端提供的产品或服务时，我们会自动收集您对我们服务的详细使用情况，作为有关网络日志保存。例如您的搜索查询内容、IP地址、浏览器的类型、电信运营商、使用的语言、访问日期和时间及您访问的网页记录等。</li>
                <li><strong>设备信息：</strong>我们会根据您在软件安装及使用中授予的具体权限，接收并记录您所使用的设备相关信息（例如设备型号、操作系统版本、设备设置、唯一设备标识符等软硬件特征信息）、设备所在位置相关信息（例如IP&nbsp;地址、GPS位置以及能够提供相关信息的WLAN接入点、蓝牙和基站等传感器信息）。</li>
            </ul>
            <p><strong>请注意，单独的设备信息、日志信息等是无法识别特定自然人身份的信息。</strong>如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将该类个人信息做匿名化、去标识化处理。</p>
            <p>3、我们通过间接方式收集到的您的个人信息</p>
            <p><strong>我们会从关联方、第三方合作伙伴获取您授权的相关信息。</strong>例如当您通过我们关联方、第三方合作伙伴网站及其移动应用软件等下单时，您向其提供的订单信息可能会转交给我们，以便我们处理您的订单并保证服务的顺利完成。</p>
            <p><strong>我们可能从第三方合作伙伴获取您授权共享的相关信息（如：账户头像、昵称），并在您同意本政策后将您的第三方账户与您的</strong><strong> 云餐巴</strong><strong>账户绑定，使您可以通过第三方账户直接登录并使用我们的产品与/或服务。</strong></p>
            <p>我们将在符合相关法律法规规定，并依据与关联方或第三方合作伙伴的约定、确信其提供的个人信息来源合法的前提下，收集并使用您的这些个人信息。</p>
            <h3><strong>（三）改善我们的产品或服务</strong></h3>
            <p><strong>为改善我们的产品或服务、向您提供个性化的信息搜索及交易服务，我们会根据您的浏览及搜索记录、设备信息、位置信息、订单信息，提取您的浏览、搜索偏好、行为习惯、位置信息等特征，基于特征标签进行间接人群画像并通过生成定制页面、推送消息通知、发送邮件、发送短信等方式向您展示、推送信息。</strong></p>
            <h3><strong>（四）为您提供安全保障</strong></h3>
            <p>为提高您使用我们及我们关联公司、合作伙伴提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或 云餐巴相关协议规则的情况，<strong>我们可能使用或整合您的个人信息、交易信息、设备信息、有关网络日志以及我们关联公司、合作伙伴取得您授权或依据法律共享的信息，来综合判断您账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。</strong></p>
            <h3><strong>（五）其他用途</strong></h3>
            <p>我们将信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意（确认同意的方式：如勾选、弹窗、站内信、邮件、短信等方式）。</p>
            <h3><strong>（六）征得授权同意的例外</strong></h3>
            <p>根据相关法律法规规定，以下情形中收集您的个人信息无需征得您的授权同意：</p>
            <p>1、与国家安全、国防安全有关的；</p>
            <p>2、与公共安全、公共卫生、重大公共利益有关的；</p>
            <p>3、与犯罪侦查、起诉、审判和判决执行等有关的；</p>
            <p>4、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；</p>
            <p>5、所收集的个人信息是您自行向社会公众公开的；</p>
            <p>6、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</p>
            <p>7、根据您的要求签订合同所必需的；</p>
            <p>8、用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；</p>
            <p>9、为合法的新闻报道所必需的；</p>
            <p>10、学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</p>
            <p>11、法律法规规定的其他情形。</p>
            <h2><strong>二、我们如何保存您的信息及个人信息跨境传输</strong></h2>
            <h3><strong>（一）您的个人信息我们将保存至您账号注销之后的十五天，除非延长保留期征得您的同意或法律法规另有规定。当您的个人信息超出我们所保存的期限后，我们会对您的个人信息进行删除或匿名化处理。</strong></h3>
            <h3><strong>（二）如我们终止服务或运营，我们将及时停止继续收集您个人信息的活动，同时会遵守相关法律法规要求提前向您通知，并在终止服务或运营后对您的个人信息进行删除或匿名化处理。</strong></h3>
            <h3><strong>（三）我们在中华人民共和国境内运营中收集和产生的信息，存储在中国境内。以下情形除外：</strong></h3>
            <p>1、法律法规有明确规定；</p>
            <p>2、获得您的授权同意；</p>
            <p>3、您使用 云餐巴跨境交易服务，且需要向境外传输您的个人信息完成交易的。</p>
            <p>针对以上情形，我们会确保依据本政策及国家法律法规要求对您的个人信息提供足够的保护。</p>
            <h2><strong>三、我们如何使用&nbsp;Cookie&nbsp;和同类技术</strong></h2>
            <h3><strong>（一）Cookie</strong></h3>
            <p>为确保网站正常运转、为您提供更个性化的用户体验和服务，我们会在您的计算机或移动设备上存储名为&nbsp;Cookie&nbsp;的小数据文件。Cookie&nbsp;通常包含标识符、站点名称以及一些号码和字符。借助于&nbsp;Cookie，网站能够存储您的偏好或购物车内的商品等数据。</p>
            <p>我们不会将&nbsp;Cookie用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除&nbsp;Cookie。有关详情，请参见&nbsp;AboutCookies.org。您可以清除计算机上保存的所有&nbsp;Cookie，大部分网络浏览器都设有阻止&nbsp;Cookie&nbsp;的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置。如需详细了解如何更改浏览器设置，请访问您使用的浏览器的相关设置页面。</p>
            <h3><strong>（二）网站信标和像素标签</strong></h3>
            <p>除&nbsp;Cookie&nbsp;外，我们还会在网站上使用网站信标和像素标签等其他同类技术。例如，我们向您发送的电子邮件可能含有链接至我们网站内容的地址链接，如果您点击该链接，我们则会跟踪此次点击，帮助我们了解您的产品或服务偏好以便于我们主动改善客户服务体验。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。</p>
            <h2><strong>四、我们如何共享、转让、公开披露您的个人信息</strong></h2>
            <h3><strong>（一）共享</strong></h3>
            <p><strong>我们不会与</strong><strong> 云餐巴</strong><strong>以外的公司、组织和个人共享您的个人信息，但以下情况除外：</strong></p>
            <p>1、在获取您授权同意的情况下共享：获得您授权同意后，我们会与其他方共享您的个人信息。</p>
            <p>2、在法定情形下共享：我们可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。</p>
            <p>3、只有共享您的信息，才能实现 云餐巴服务基本功能或提供您需要的服务。</p>
            <p>4、与我们的关联方共享：<strong>我们可能会将您的个人信息与我们的关联方共享。但我们只会共享必要的个人信息，且受本政策中所声明目的的约束。</strong></p>
            <p>5、与授权合作伙伴共享：<strong>仅为实现本政策中声明的目的，我们可能会与授权合作伙伴共享您的订单信息、账户信息、设备信息以及位置信息，以提供更好的客户服务和用户体验。</strong>例如，在您上网购买我们的商品时，我们必须与物流服务提供商共享您的个人信息才能安排送货，或者安排合作伙伴提供服务。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。<strong>我们的授权合作伙伴无权将共享的个人信息用于与</strong><strong> 云餐巴</strong><strong>服务无关的其他用途。</strong></p>
            <p>目前，我们的授权合作伙伴包括以下类型：</p>
            <p>l&nbsp;&nbsp;广告、分析服务类的授权合作伙伴。除非得到您的许可，否则我们不会将您的个人身份信息（指可以识别您身份的信息，例如姓名或电子邮箱，通过这些信息可以联系到您或识别您的身份）与提供广告、分析服务的合作伙伴共享。我们会向这些合作伙伴提供有关其广告覆盖面和有效性的信息，而不会提供您的个人身份信息，或者我们将这些信息进行汇总，以便它不会识别您个人。例如，只有在广告主同意遵守我们的广告发布准则后，我们才可能会告诉广告主他们广告的效果如何，或者有多少人看了他们广告或在看到广告后购买了其商品，或者向这些合作伙伴提供不能识别个人身份的统计信息（例如"女性，25-29岁，喜欢购买服饰类商品"），帮助他们了解其受众或顾客。</p>
            <p>l&nbsp;&nbsp;供应商、服务提供商和其他合作伙伴。<strong>我们可能会将您的个人信息共享给支持我们业务的供应商、服务提供商和其他合作伙伴。</strong>这些支持包括提供基础设施技术服务、物流配送服务、支付服务、提供客户服务、数据处理、分析我们服务的使用方式、衡量广告或服务的有效性或进行学术研究和调查。</p>
            <p>l&nbsp;&nbsp;第三方商家。<strong>我们必须将您的订单信息中与交易有关的必要信息与第三方商家共享来实现您向其购买商品或服务的需求，并促使其可以完成后续的售后服务。</strong></p>
            <p>对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本权政策以及其他任何相关的保密和安全措施来处理个人信息。</p>
            <h3><strong>（二）转让</strong></h3>
            <p>我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</p>
            <p>1、在获取授权同意的情况下转让：获得您的授权同意后，我们会向其他方转让您的个人信息；</p>
            <p>2、在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。</p>
            <h3><strong>（三）披露</strong></h3>
            <p>我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会披露您的个人信息：</p>
            <p>1、获得您授权同意或基于您的主动选择，我们可能会披露您的个人信息；</p>
            <p><strong>2、根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，我们可能会依据所要求的个人信息类型和披露方式披露您的个人信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求接收方必须出具与之相应的法律文件，如传票或调查函。</strong></p>
            <h3><strong>（四）共享、转让、披露个人信息时事先征得授权同意的例外</strong></h3>
            <p>以下情形中，共享、转让、披露您的个人信息无需事先征得您的授权同意：</p>
            <p>1、与国家安全、国防安全有关的；</p>
            <p>2、与公共安全、公共卫生、重大公共利益有关的；</p>
            <p>3、与犯罪侦查、起诉、审判和判决执行等有关的；</p>
            <p>4、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
            <p>5、您自行向社会公众公开的个人信息；</p>
            <p>6、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</p>
            <p>根据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。</p>
            <p><strong>五、我们如何保护您的个人信息安全</strong></p>
            <p>我们非常重视个人信息安全，成立了专门的安全团队，并采取一切合理可行的措施，保护您的个人信息。</p>
            <h3><strong>（一）数据安全技术措施</strong></h3>
            <p>我们会采用符合业界标准、合理可行的安全防护措施保护您提供的个人信息，防止您的个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。</p>
            <p>1、我们会采用加密技术确保数据的保密性。例如，在您的浏览器与服务器之间交换数据（如信用卡信息）时受&nbsp;SSL（Secure Socket Layer）加密保护。</p>
            <p>2、我们会采用数据脱敏技术增强个人信息在使用中安全性。</p>
            <p>3、我们对 云餐巴网站提供HTTPS（Hyper Text Transfer Protocol over Secure Socket Layer）安全浏览方式。</p>
            <p>4、我们会采用受信赖的保护机制防止数据遭到恶意攻击。</p>
            <p>5、我们会采用严格的数据访问权限控制和多重身份认证技术保护个人信息，避免数据被违规使用。</p>
            <h3><strong>（二）其他安全措施</strong></h3>
            <p>1、我们通过建立数据分级制度、数据安全管理规范、数据安全开发规范来管理规范个人信息的存储和使用。</p>
            <p>2、我们会部署访问控制机制，确保只有授权人员才可访问您的个人信息。我们同时要求可能接触到您个人信息的所有人员履行相应的保密义务。如果未能履行这些义务，可能会被追究法律责任或被中止与我们的合作关系。</p>
            <p>3、我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。</p>
            <h3><strong>（三）我们有行业先进的以数据为核心，围绕数据生命周期进行的数据安全管理体系，从组织建设、制度设计、人员管理、产品技术等方面多维度提升整个系统的安全性。</strong></h3>
            <h3><strong>（四）互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。</strong></h3>
            <h3><strong>（五）安全提示</strong></h3>
            <p>1、互联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件等与其他用户的交流方式无法确定是否完全加密，<strong>我们建议您使用此类工具时请使用复杂密码，并注意保护您的个人信息安全。</strong></p>
            <p>2、在使用 云餐巴服务与第三方进行沟通或购买商品及服务时，您不可避免地要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或联系地址。<strong>请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。</strong></p>
            <p><strong>3、请注意，您在使用我们服务时自愿共享甚至公开分享的信息，可能会涉及您或他人的个人信息甚至个人敏感信息，如您在评价或种草社区评论时选择上传包含个人信息的图片。请您更加谨慎地考虑，是否在使用我们的服务时共享甚至公开分享相关信息。</strong></p>
            <p><strong>4、请您理解，由于技术的限制以及风险防范的局限，即便我们已经尽量加强安全措施，也无法始终保证信息百分之百的安全。您需要了解，您接入</strong><strong> 云餐巴</strong><strong>服务所用的系统和通讯网络，有可能因我们可控范围外的情况而发生问题。</strong></p>
            <h3><strong>（六）安全事件处置。在不幸发生信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们会及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报信息安全事件的处置情况。</strong></h3>
            <h3><strong>（七）如果您对我们的个人信息保护有任何疑问，可通过本政策最下方约定的联系方式联系我们。如您发现自己的个人信息泄密，尤其是您的账户及密码发生泄露，请您立即通过本政策最下方【如何联系我们】约定的联系方式联络我们，以便我们采取相应措施。</strong></h3>
            <h2><strong>六、您如何管理您的个人信息</strong></h2>
            <p>我们鼓励您更新和修改您的信息以使其更准确有效，也请您理解，<strong>您更正、删除、撤回授权或注销账号的决定，并不影响我们此前基于您的授权而开展的个人信息处理</strong>。除法律法规另有规定，当您更正、删除您的个人信息或申请注销账号时，我们可能不会立即从备份系统中更正或删除相应的信息，但会在备份更新时更正或删除这些信息。</p>
            <p>您可以通过以下方式来管理您的个人信息：&nbsp;</p>
            <h3><strong>（一）访问、更正您的个人信息</strong></h3>
            <p>您有权访问、更正您的个人信息，法律法规规定的例外情况除外。您可以通过以下方式自行访问、更正您的个人信息：</p>
            <p>1、账户信息：如果您希望访问或编辑您的账户中的个人基本信息（如账户密码、昵称、性别、地址信息、感兴趣的类别等），您可以登录移动客户端账号通过设置执行此类操作。</p>
            <p>2、搜索信息：您可以通过点击PC端或移动端搜索框访问或清除您的搜索历史记录。</p>
            <p>3、浏览信息：您可以登录移动客户端账号通过"我的&mdash;我的足迹"访问或清除您的浏览历史记录。您可以登陆移动客户端账号通过"我的--设置&mdash;个人信息&mdash;感兴趣的类别"查看和修改您的兴趣设置。</p>
            <p>4、订单信息：PC端您可以通过访问"我的订单"页面查看您的所有订单状态；移动端您可以通过"我的&mdash;我的订单"查看您的所有订单状态。</p>
            <p>如果您无法通过上述路径访问、更正该等个人信息，您可以通过我们的在线客服与我们取得联系。对于您在使用 云餐巴服务过程中产生的其他个人信息需要访问或更正，我们会根据本政策所列明的方式、期限及范围来响应您的请求。</p>
            <h3><strong>（二）删除您的个人信息</strong></h3>
            <p>您可以通过"（一）访问、更正您的个人信息"中列明的方式自主删除您的部分个人信息。在部分信息删除时，我们可能会要求您进行身份验证，以保障信息安全。</p>
            <p>在以下情形中，您可以向我们提出删除个人信息的请求：</p>
            <p>1、如果我们处理个人信息的行为违反法律法规；</p>
            <p>2、如果我们收集、使用您的个人信息，却未征得您的授权同意；</p>
            <p>3、如果我们处理个人信息的行为严重违反了与您的约定；</p>
            <p>4、如果我们不再为您提供 云餐巴服务。</p>
            <p>若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。</p>
            <h3><strong>（三）改变您授权同意的范围或撤回您的授权</strong></h3>
            <p>请您理解，有些基本的个人信息是您使用 云餐巴服务所必需的，但大多数其他个人信息的提供是由您决定的。您可以通过删除信息、关闭设备功能等方式改变您授权我们继续调用附加功能收集您个人信息的范围或撤回您的授权。当您撤回授权后，我们将无法继续为您提供您撤回授权所对应的服务，也不再处理您相应的个人信息。</p>
            <p><strong>如果您不想接受我们给您发送的促销信息，您随时可通过以下方式取消：</strong></p>
            <p>1、您可以根据短信退订指引要求我们停止发送促销短信。</p>
            <p>2、您可以根据促销邮件中的退订指引来取消我们给您发送促销邮件。</p>
            <p>3、您可以通过移动客户端"我的-设置-消息通知设置"设置是否接受我们通过"通知"推送给您的商品和促销信息。</p>
            <h3><strong>（四）</strong><strong> 云餐巴</strong><strong>账户注销</strong></h3>
            <p>您可以自行在"注销账户"页面（例如，您可以在 云餐巴移动客户端"我的&mdash;帮助与客服"中搜索"注销"，进入注销页面）提交 云餐巴账户注销申请。关于您注销账户的方式以及您应满足的条件，请详见"注销账户"页面的《账号注销须知》。</p>
            <p>在您的 云餐巴账户注销之后，我们将停止为您提供 云餐巴服务，并依据您的要求，删除或匿名化您的个人信息，法律法规另有规定的除外。</p>
            <h3><strong>（五）约束信息系统自动决策</strong></h3>
            <p>在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将在不侵害 云餐巴商业秘密或其他用户权益、社会公共利益的前提下提供适当的救济方式。</p>
            <h3><strong>（六）响应您的上述请求</strong></h3>
            <p>为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。</p>
            <p>一般情况下，我们将在三十天内做出答复。如您不满意，还可以通过本政策下方提供的联系方式与我们联系。</p>
            <p>对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。</p>
            <p>在以下情形中，按照法律法规要求，我们将无法响应您的请求：</p>
            <p>1、与国家安全、国防安全有关的；</p>
            <p>2、与公共安全、公共卫生、重大公共利益有关的；</p>
            <p>3、与犯罪侦查、起诉、审判和判决执行等有关的；</p>
            <p>4、有充分证据表明个人信息主体存在主观恶意或滥用权利的；</p>
            <p>5、响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；</p>
            <p>6、涉及商业秘密的。</p>
            <h2><strong>七、第三方服务</strong></h2>
            <p> 云餐巴服务可能链接至第三方提供的社交媒体或其他服务（包括网站或其他服务形式）。包括：</p>
            <p>1、您可利用"分享"键将某些内容分享到 云餐巴服务，或您可利用第三方服务登录 云餐巴服务。这些功能可能会收集您的信息（包括您的日志信息），并可能在您的电脑装置COOKIES，从而正常运行上述功能；</p>
            <p>2、我们通过广告或我们服务的其他方式向您提供链接，使您可以接入第三方的服务或网站；</p>
            <p>3、其他接入第三方服务的情形。</p>
            <p><strong>该等第三方社交媒体或其他服务由相关的第三方负责运营。您使用该等第三方的社交媒体服务或其他服务（包括您向该等第三方提供的任何信息），须受第三方自己的服务条款及信息保护声明（而非本政策）约束，您需要仔细阅读其条款。本政策仅适用于我们所收集的任何信息，并不适用于任何第三方提供的服务或第三方的信息使用规则，而我们对任何第三方使用由您提供的信息不承担任何责任。</strong></p>
            <h2><strong>八、未成年人信息的保护</strong></h2>
            <p>我们建议：<strong>任何未成年人参加网上活动应事先取得家长或其法定监护人（以下简称"监护人"）的同意。</strong>我们将根据国家相关法律法规的规定保护未成年人的相关信息。</p>
            <p>我们鼓励父母或监护人指导未成年人使用 云餐巴服务。我们建议未成年人鼓励他们的父母或监护人阅读本《隐私政策》，并建议未成年人在提交信息之前寻求父母或监护人的同意和指导。</p>
            <h2><strong>九、通知和修订</strong></h2>
            <h3><strong>（一）我们可能适时修改本政策的条款，该等修改构成本政策的一部分。我们会通过我们网站、移动端或其他官方路径发出更新版本或以其他适当方式提醒您相关内容的更新。如您仍然继续使用</strong><strong> 云餐巴</strong><strong>服务的，即表示同意受经修订的本政策的约束。</strong></h3>
            <h3><strong>（二）我们可能在必需时（例如当我们由于系统维护而暂停某一项服务时）发出与服务有关的公告。您可能无法取消这些与服务有关、性质不属于推广的公告。</strong></h3>
            <h2><strong>十、如何联系我们</strong></h2>
            <p>如您对本政策或您个人信息的相关事宜有任何疑问、意见或建议，您可以通过访问我们的在线客服系统或拨打我们任何一部客服电话等多种方式与我们联系，电话：0594-7997779。</p>
        </div>
    </div>

</template>

<script>
    export default {
        name: "PrivacyPolicy"
    }
</script>

<style scoped>
    .header{
        height: 183px;
        background: linear-gradient(270deg, #FADB86, #F40C17);
        box-shadow: 0px 44px 13px 0px rgba(138, 23, 0, 0.13) inset;
    }
    .privacy {
        font-size: 20px;
        line-height: 1.8em;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #494949;
        padding: 50px 281px 110px 268px;
    }
    .privacy-header {
        font-size: 35px;
        font-weight: bold;
        color: #DB0F1A;
        text-align: center;
        margin-bottom: 46px;
    }
    .privacy p{
        text-indent: 2em;
    }
    .privacy strong{
        font-weight: bold;
    }

    .privacy h2{
        margin-top: .3em;
        font-size: 26px;
    }
</style>
