<template>
    <div class="container">
        <div class="banner">
            <el-carousel height="813px" :autoplay="false">
                <el-carousel-item v-for="(item,index) in banners" :key="index" style="text-align: center">
                    <img :src="VUE_APP_BASE_API+item.img" style="height: 100%"/>
                    <div v-if="item.append" class="banner_wrapper"
                         style="position:absolute;text-align: left;transform: translateX(-50%);top:187px;left: 50%;z-index: 100; display: flex">
                        <div class="product" style="width: 431px;position: relative;display: inline-block">
                            <img :src="VUE_APP_BASE_API+'/officialWebsite/400321090%402x.png'" style="width: 100%"
                                 alt="">
                            <img :src="VUE_APP_BASE_API+'/officialWebsite/614012d0a81b7232235e41bf931a611%402x.png'"
                                 style="width: 89.1%;position:absolute;transform: translateX(-50%);top:11%;left: 50%;z-index: -1"
                                 alt="">
                        </div>
                        <div class="desc">
                            <h1>莆田特色餐饮公共服务平台</h1>
                            <h2>暖心·暖城·暖万家</h2>
                            <div class="app-wrapper">
                                <div class="app">
                                    <!--                  <img :src="VUE_APP_BASE_API+'/officialWebsite/6fc4a22ac2d3adc76dc860183f0a3e9%402x.png'" alt="商家端logo" width="118">-->
                                    <!--                  <p>商家端</p>-->
                                    <img :src="VUE_APP_BASE_API+'/min/xiaochengxu.png'" width="176">
                                </div>
                                <div class="download">
                                    <!--                  <a href="https://ptcb.oss-cn-hangzhou.aliyuncs.com/apk/app-mealbar-mch-release.1.7.apk">-->
                                    <!--                    <img :src="VUE_APP_BASE_API+'/officialWebsite/%E7%BB%84%202%20%E6%8B%B7%E8%B4%9D%402x.png'" alt="">-->
                                    <!--                  </a>-->
                                    <!--                  <a href="https://apps.apple.com/cn/app/%E4%BA%91%E9%A4%90%E5%B7%B4%E5%95%86%E5%AE%B6%E7%89%88/id1552373411">-->
                                    <!--                    <img :src="VUE_APP_BASE_API+'/officialWebsite/%E7%BB%84%202%402x.png'" alt="">-->
                                    <!--                  </a>-->
                                    <p style="font-size: 17px;font-family: Source Han Sans CN;font-weight: bold;color: #FDFDFC;line-height: 30px;margin-top: 50px">
                                        扫描二维码</p>
                                    <p style="font-size: 17px;font-family: Source Han Sans CN;font-weight: bold;color: #FDFDFC;line-height: 30px;">
                                        进入云餐巴小程序</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="advance" style="text-align: center">
            <div class="title">
                <h3>平台优势</h3>
                <h4 class="eng-title">PRDUCT ADVANTAGES</h4>
            </div>
            <div class="content">
                <ul>
                    <li v-for="(item,index) in advances" :key="index">
                        <img :src="VUE_APP_BASE_API+item.img" alt="图片" height="203">
                        <p class="title" style="color:#3E3E3E">{{item.title||"暂无标题"}}</p>
                        <!--            <p class="content" style="color:#666666;word-break: keep-all;">{{item.desc||"暂无描述"}}</p>-->
                        <p class="content" style="color:#666666" v-html="item.desc"></p>
                    </li>
                </ul>
            </div>
            <!--      <div class="action">-->
            <!--        <div class="more" @click="toMore">-->
            <!--          查看更多-->
            <!--        </div>-->
            <!--      </div>-->
        </div>
        <div class="introduce"
             :style="{'background-image':'url('+ VUE_APP_BASE_API+'/officialWebsite/%E5%9B%BE%E5%B1%82%209%402x.png)'}">
            <div class="left">
                <h3 class="title">平台简介</h3>
                <!--        <p class="content">{{profile.content||"暂无介绍"}}</p>-->
                <div class="content" v-html="profile.content"></div>
            </div>
            <div class="right">
                <img :src="VUE_APP_BASE_API+profile.img" alt="企业介绍">
            </div>
        </div>
        <div class="service-introduce"  :style="{'background-image':'url('+ VUE_APP_BASE_API+'/min/img-9.jpg)'}">
            <div class="title">
                <h3>服务介绍</h3>
                <h4 class="eng-title">SERVICE INTRODUCTION</h4>
            </div>
            <div class="content">
                <ul>
                    <li :class="{'active':serviceIndex === index}" v-for="(item,index) in service" :key="index"
                        @click="toChangeService({index:index})">
                        <img :src="VUE_APP_BASE_API+(serviceIndex === index?item.activeIcon:item.icon)" alt="图片" height="65">
                        <p class="title">{{item.title||"暂无标题"}}</p>
                    </li>
                </ul>
                <div style="display: inline-block;height: 554px;width:296px;position: relative;margin-left: 150px;border-radius: 50px;overflow: hidden">
                    <img :src="VUE_APP_BASE_API+'/officialWebsite/iPhone%20X%402x.png'" alt=""
                         style="height: 100%;vertical-align: middle;position: absolute;z-index:100;left:0;top:0">
                    <el-carousel ref="carousel" class="module-images" height="554px"
                                 :autoplay="false" indicator-position="none" arrow="never">
                        <el-carousel-item class="image-wrapper" v-for="(item,index) in service" :key="index">
<!--                            :style="{height: '100%','background-image':'url('+ VUE_APP_BASE_API+'/officialWebsite/iPhone%20X%402x.png)'}">-->
                            <span style="display: inline-block;height: 100%;vertical-align: middle"></span>
                            <img :src="VUE_APP_BASE_API+item.img" alt=""
                                 style="height: 100%;vertical-align: middle">
                        </el-carousel-item>
                    </el-carousel>
                </div>

            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'Home',
        data() {
            return {
                VUE_APP_BASE_API: process.env.VUE_APP_BASE_API,
                bannerLogo: '/officialWebsite/%E5%9B%BE%E5%B1%82%203%402x.png',
                banners: [
                    {img: '/officialWebsite/banner-bg01.jpg', append: true},
                    {img: '/officialWebsite/2b889ddc5d4026d2cf81ec848edfa86.jpg'},
                    {img: '/officialWebsite/banner-bg01.jpg', append: true},
                ],
                tabs: [
                    {name: '首页'},
                    {name: '商家入驻'},
                    {name: '隐私和政策'},
                ],
                tabIndex: 0,
                advances: [
                    {
                        img: '/officialWebsite/%E5%9B%BE%E5%B1%82%204%402x.png',
                        title: '品牌保障',
                        desc: '莆田市政府牵头、<br/>' +
                            '国资参股的公共餐饮服务平台，<br/>' +
                            '品牌更具公信力。<br/>'
                    },
                    {
                        img: '/officialWebsite/%E5%9B%BE%E5%B1%82%206%402x.png',
                        title: '文化赋能',
                        desc: '深度挖掘莆田特色民俗,<br/>' +
                            '风情与美食文化，<br/>' +
                            '品牌更具文化内涵。<br/>'
                    },
                    {
                        img: '/officialWebsite/%E5%9B%BE%E5%B1%82%207%402x.png',
                        title: '质量保证',
                        desc: '应用先进的平台系统，对订餐、<br/>' +
                            '取餐、就餐等进行全流程的食品安全管控，<br/>' +
                            '让大家吃的更便利、更健康、更安全。<br/>'
                    },
                    {
                        img: '/officialWebsite/%E5%9B%BE%E5%B1%82%205%402x.png',
                        title: '全面服务 ',
                        desc: '提供预约订餐、外卖配送、<br/>' +
                            '移动便民餐车、名厨上门、<br/>' +
                            '家宴定制等多种场景的全面服务。<br/>'
                    }
                ],
                profile: {
                    content: "<p>莆田餐巴旗下的“云餐巴”是由莆田市政府牵头，国投集团权属企业振兴乡村集团与福建豆讯科技有限公司倾力打造的便民惠民利民的莆田特色餐饮公共服务平台。</p>" +
                        "<p>平台秉承“暖心、暖城、暖万家”的宗旨，依托“政府指导、技术驱动、专业运营”的优势，应用“互联网+文化+餐饮”的模式，深度挖掘莆田特色美食与民俗文化，打造集预约订餐、外卖配送、中央厨房、移动便民餐车、名厨上门、家宴定制、美食达人分享，以及家政预约、社区生活服务等为一体的高品质生活服务平台，让广大市民和来莆游客乐享莆田幸福生活。</p>",
                    img: "/officialWebsite/%E5%9B%BE%E5%B1%82%208%402x.png"
                },
                service: [
                    {
                        activeIcon:'/officialWebsite/muffin%402x.png',
                        icon: '/officialWebsite/muffin%402x(1).png',
                        title: '美食板块 FOOD',
                        img: '/officialWebsite/0e398b5ecae09ef7aa2df36e5580bf6%402x.png'
                    },
                    {
                        activeIcon:'/officialWebsite/tropical%402x.png',
                        icon: '/officialWebsite/tropical%402x(1).png',
                        title: '旅游板块 TOURISM',
                        img: '/officialWebsite/ab6614b9cb8a7433ac5a4b3e84997e9%402x.png'
                    },
                    {
                        activeIcon:'/officialWebsite/donut%402x.png',
                        icon: '/officialWebsite/donut%402x(1).png',
                        title: '热点板块 HOT TOPIC',
                        img: '/officialWebsite/a4aa18414df6e4a597bcd87773bbdf5%402x.png'
                    }
                ],
                serviceIndex: 0,
            }
        },
        created() {
            this.showService()
        },
        methods: {
            showService(){
                setInterval((_this = this) => {
                    // debugger
                    _this.toChangeService({index: (_this.serviceIndex + 1) % 3})
                }, 5000)
            },
            toChangeService({index}) {
                this.serviceIndex = index

                this.$refs.carousel && this.$refs.carousel.setActiveItem(index)
            },
        }
    }
</script>
<style src="../style/global.css">
</style>
<style scoped>
    img {
        max-width: 100%;
    }

    /*********** banner \/ **********/
    .banner {

    }

    .banner .tabs {
        height: 36px;
        line-height: 30px;
        position: absolute;
        top: 39px;
        right: 16.72%;
        z-index: 100
    }

    .tabs span {
        display: inline-block;
        height: 36px;
        font-size: 18px;
        font-weight: 400;
        color: #FFFFFF;
        margin-right: 72px;
        cursor: pointer;
    }

    .tabs span:last-child {
        margin-right: 0;
    }

    .tabs span.active {
        font-size: 24px;
        border-bottom: 1px solid #EC322F;
        color: #EC322F;
    }

    .banner .el-carousel__item h3 {
        color: #475669;
        font-size: 14px;
        opacity: 0.75;
        line-height: 150px;
        margin: 0;
    }

    .banner .desc {
        display: inline-block;
        margin: 77px 0 0 11%;
        width: 806px;
        vertical-align: top;
    }

    .desc h1 {
        font-size: 58px;
        font-weight: 800;
    }

    .desc h2 {
        font-size: 30px;
        color: #FFFFFF;
        line-height: 30px;
        padding: 33px 0 87px;
    }

    .desc h2:after {
        content: "";
        display: block;
        margin-top: 33px;
        width: 53px;
        height: 4px;
        background: #FFFFFF;
    }

    .desc .app-wrapper {

    }

    .desc .app {
        display: inline-block;
        margin-right: 27px;
    }

    .app p {
        width: 100%;
        text-align: center;
        font-size: 25px;
        color: #FCFBFB;
        line-height: 30px;
        padding-top: 17px;
        margin-right: 27px;
    }

    .app-wrapper .download {
        /*height: 180px;*/
        display: inline-block;
        vertical-align: top;
    }

    .app-wrapper .download img {
        height: 39px;
        display: block;
        cursor: pointer;
    }

    .app-wrapper .download img:first-child {
        margin: 10px 0 19px 0;
    }

    /*********** banner /\ **********/

    /*********** advance \/ **********/
    .advance {
        margin: 112px 0 92px 0;
    }

    .advance .title h3 {
        font-size: 36px;
        font-weight: bold;
        color: #262933;
        line-height: 30px;
    }

    .advance .title h4.eng-title {
        font-size: 18px;
        font-weight: 400;
        color: #F24A40;
        line-height: 46px;
        vertical-align: middle;
    }

    .advance .title h4.eng-title:before {
        content: "";
        display: inline-block;
        width: 360px;
        height: 1px;
        background: linear-gradient(-90deg, #F2473D, #FFFFFF);
        vertical-align: middle;
    }

    .advance .title h4.eng-title:after {
        content: "";
        display: inline-block;
        width: 360px;
        height: 1px;
        background: linear-gradient(90deg, #F2473D, #FFFFFF);
        vertical-align: middle;
    }

    .advance .content {
        margin-top: 65px;
    }

    .advance .content li {
        display: inline-block;
        width: 20.5%;
        vertical-align: top;
    }

    .advance .content li .title {
        font-size: 24px;
        font-weight: 500;
        color: #3E3E3E;
        line-height: 30px;
    }

    .advance .content li .content {
        font-size: 20px;
        color: #666666;
        line-height: 30px;
    }

    .action {
        display: inline-block;
        margin-top: 75px;
    }

    .action .more {
        width: 197px;
        height: 62px;
        border: 2px solid #EC322F;
        border-radius: 31px;

        font-size: 24px;
        font-weight: 400;
        color: #EC322F;
        line-height: 62px;
        cursor: pointer;
    }

    /*********** advance /\ **********/

    /*********** introduce \/ **********/

    .introduce {
        background: no-repeat center center/100% auto;
        box-sizing: border-box;
        height: 812px;
        display: flex;
        justify-content: center;
        align-items: center;
        /*padding: 158px 0;*/
    }

    .introduce .left {
        width: 683px;
        margin-right: 25px;
        font-size: 25px;
        font-weight: 400;
        color: #FAFBFC;
        line-height: 30px;

    }

    .left h3 {
        display: inline-block;
        font-size: 36px;
        font-weight: bold;
        color: #FAFBFC;
        line-height: 30px;
        height: 54px;
        border-bottom: 3px solid #FAFBFC;
        margin-bottom: 37px;
    }

    .left .content {
        text-indent: 2em;
        line-height: 1.8em;
    }

    .introduce .right {
        width: 700px;
        padding-top: 38px;
    }

    /*********** introduce /\ **********/

    /*********** advance \/ **********/
    .service-introduce {
        background: center center/100% auto no-repeat;
        padding: 112px 0 92px 0;
        text-align: center
    }

    .service-introduce .title h3 {
        font-size: 36px;
        font-weight: bold;
        color: #262933;
        line-height: 30px;
    }

    .service-introduce .title h4.eng-title {
        font-size: 18px;
        font-weight: 400;
        color: #F24A40;
        line-height: 46px;
        vertical-align: middle;
    }

    .service-introduce .title h4.eng-title:before {
        content: "";
        display: inline-block;
        width: 360px;
        height: 1px;
        background: linear-gradient(-90deg, #F2473D, #FFFFFF) ;
        vertical-align: middle;
    }

    .service-introduce .title h4.eng-title:after {
        content: "";
        display: inline-block;
        width: 360px;
        height: 1px;
        background: linear-gradient(90deg, #F2473D, #FFFFFF);
        vertical-align: middle;
    }

    .service-introduce .content {
        margin-top: 65px;
    }

    .service-introduce .content > * {
        vertical-align: middle;
    }

    .service-introduce .content ul {
        display: inline-block;
        width: 800px;
    }

    .service-introduce .content li {
        /*display: inline-block;*/
        /*width: 20.5%;*/
        /*vertical-align: top;*/
        text-align: left;
        margin-bottom: 60px;
        font-size: 38px;
        cursor: pointer;
        color: #0000
    }

    .service-introduce .content li:last-child {
        margin-bottom: 0px;
    }

    .service-introduce .content li > * {
        vertical-align: middle;
    }

    .service-introduce .content li .title {
        margin-left: 67px;
        display: inline-block;
        font-weight: bold;
        color: #262933;
    }

    .service-introduce .content li.active .title {
        font-size: 68px;
        color: #BC1D21;
    }

    .service-introduce .content .module-images {
        display: inline-block;
        /*margin-left: 150px;*/
        width: 290px;
        height: 554px;
    }
    .service-introduce .content .image-wrapper{
        background: center/auto 100% no-repeat;
    }


    /*********** advance /\ **********/

</style>
